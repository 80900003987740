import React from 'react';

import '@react-website-themes/classy-docs/styles/variables';
import '@react-website-themes/classy-docs/styles/global';
import Branding from '@react-website-themes/classy-docs/components/Branding';
import Header from '@react-website-themes/classy-docs/components/Header';
import Layout from '@react-website-themes/classy-docs/components/Layout';
import Menu from '@react-website-themes/classy-docs/components/Menu';

import config from 'content/meta/config';
import menuItems from 'content/meta/menu';
import PortalLink from '../content/meta/portalLink';

const IndexPage = () => {
  const { headerTitle, headerSubTitle } = config;

  return (
    <Layout>
      <Header>
        <Branding title={headerTitle} subTitle={headerSubTitle} />
        <Menu items={menuItems} />
        <PortalLink />
      </Header>
    </Layout>
  );
};

export default IndexPage;
